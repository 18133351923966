import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import { Workbox } from "workbox-window";
import { Tooltip } from "@oruga-ui/oruga-next";
import Toast from "vue-toastification";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import App from "./App.vue";
import router from "./components/Router";
import piniaUrlSync from "./modules/util/pinia-plugin-url-sync";
import { CesiumController } from "./modules/CesiumController";

function satvisSetup() {
  // Enable sentry for production version
  if (window.location.href.indexOf("csgroup.space" > -1)) {
    Sentry.init({
      dsn: "https://ae95dd796299471bbf127b9d4a1b4520@o4504015517122560.ingest.sentry.io/4504015518957568",
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["localhost", ".*.csgroup.space", /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }

  // Setup and init app
  const app = createApp(App);
  const cc = new CesiumController();
  app.config.globalProperties.cc = cc;
  const pinia = createPinia();
  const state = pinia.state.value;
  pinia.use(({ store }) => { store.router = markRaw(router); });
  pinia.use(piniaUrlSync);
  app.use(pinia);
  app.use(router);
  app.use(Tooltip);
  app.use(Toast, {
    position: "bottom-right",
  });
  app.mount("#app");
  window.app = app;

  // Register service worker
  if ("serviceWorker" in navigator && !window.location.href.includes("localhost")) {
    const wb = new Workbox("sw.js");
    wb.addEventListener("controlling", (evt) => {
      if (evt.isUpdate) {
        console.log("Reloading page for latest content");
        window.location.reload();
      }
    });
    wb.register();
  }

  return { app, state, cc };
}

export default satvisSetup;
